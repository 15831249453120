@import '../node_modules/bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/variables";

$primary: #4db7b5;
$light-primary: #c3e1e4;
$dark-primary: #47a8a7;

$secondary: #bc3e30;
$light-secondary: #ff808a;
$dark-secondary: #7f000a;

$light-tertiary: #e6e6e6;
$tertiary: #3e4546;

$medium: #a3d9ea;
$light-medium: #62a9e2;
$dark-medium: #2747a1;

:root {
    /* Primary */

    --primary: #4db7b5;
    --primary-rgb: 77, 183, 181;

    --light-primary: #c3e1e4;
    --light-primary-rgb: 195, 225, 228;

    --dark-primary: #47a8a7;
    --dark-primary-rgb: 71, 168, 167;

    --bs-primary: #4db7b5;
    --bs-primary-rgb: 77, 183, 181;

    /* Secondary */

    --secondary: #bc3e30;
    --dark-secondary-rgb: 188, 62, 48;

    --light-secondary: #ff808a;
    --light-secondary-rgb: 255, 128, 138;

    --dark-secondary: #7f000a;
    --dark-secondary-rgb: 127, 0, 10;

    --bs-secondary: #bc3e30;
    --bs-secondary-rgb: 188, 62, 48;

    /* Tertiary */

    --light-tertiary: #e6e6e6;
    --light-tertiary-rgb: 230, 230, 230;

    --tertiary: #3e4546;
    --tertiary-rgb: 62, 69, 70;

    /*
    --dark-tertiary: #266d4a;
    --dark-tertiary-rgb: 38, 109, 74;*/

    /* Medium */

    --medium: #a3d9ea;
    --dark-medium-rgb: 163, 217, 234;

    --light-medium: #62a9e2;
    --light-medium-rgb: 98, 169, 226;

    --dark-medium: #2747a1;
    --dark-medium-rgb: 39, 71, 161;

    /* Customs */
    
    --bs-light: #c3e1e4;
    --bs-light-rgb: 195, 225, 228;
    --ok: #00ca68;
    --no: #d82222;
    --wait: #ffe227;
}

.bg-light {
    --bs-bg-opacity: .2;
}

.dropdown-menu {
    --bs-dropdown-link-hover-bg: rgba(var(--bs-light-rgb),.2);
}

.navbar-dark {
    --bs-navbar-color: rgba(255, 255, 255, 1);
    --bs-navbar-hover-color: rgba(255, 255, 255, 0.8);
    /*--bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);*/
}

.alert-primary {
    --bs-alert-color: var(--dark-primary);
    --bs-alert-bg: rgba(var(--primary-rgb), .2) !important;
    --bs-alert-border-color: var(--primary);
}

.alert-primary a, .alert-primary a:hover {
    color: var(--dark-primary) !important;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$primary};
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$dark-primary};
    --bs-btn-hover-border-color: #{$dark-primary};
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$dark-primary};
    --bs-btn-active-border-color: #{$dark-primary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$primary};
    --bs-btn-disabled-border-color: #{$primary};
}

.btn-outline-primary {
    --bs-btn-color: #{$dark-primary};
    --bs-btn-border-color: #{$dark-primary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$dark-primary};
    --bs-btn-hover-border-color: #{$dark-primary};
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$dark-primary};
    --bs-btn-active-border-color: #{$dark-primary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$dark-primary};
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #{$dark-primary};
    --bs-gradient: none;
}

.btn-secondary,
.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$dark-secondary};
    --bs-btn-hover-border-color: #{$dark-secondary};
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$dark-secondary};
    --bs-btn-active-border-color: #{$dark-secondary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$secondary};
    --bs-btn-disabled-border-color: #{$secondary};
}

.btn-outline-secondary,
.btn-outline-danger {
    --bs-btn-color: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #{$secondary};
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$secondary};
    --bs-btn-active-border-color: #{$secondary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$secondary};
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #{$secondary};
    --bs-gradient: none;
}

/* Customs */

.bg-primary-dark {
    background-color: var(--dark-primary);
}

html, body {
    height: calc(100% - 15px);
}

body {
    padding-top: 90px;
}

#main-content {
    min-height: calc(100% - 45px);
    margin-bottom: 30px;
    padding-top: 10px;;
    padding-bottom: 10px;
}

.accordion-button {
    color: var(--bs-body-color);
    background-color: rgba(var(--bs-light-rgb), .2);
}

.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: var(--primary);
    box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--primary);
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .accordion-body {
    background-color: rgba(var(--bs-light-rgb), .2);
}

.accordion .accordion-body table {
    background-color: #fff;
}

.btn fa-icon.left {
    float: left;
    margin-right: -20px;
}

.btn fa-icon.right {
    float: right;
    margin-left: -20px;
}

.circle {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: inline-block;
    background: var(--light-primary);
    margin-top: 5px;
}

.circle-preview {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: inline-block;
    background: var(--light-primary);
    margin-top: 5px;
}

.ok {
    background: var(--ok) !important;
    color: #fff !important;
}

.no {
    background: var(--no) !important;
    color: #fff !important;
}

.wait {
    background: var(--wait) !important;
    color: #fff !important;
}

.table-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}

.table .list-group-item {
    background: transparent;
}

.table th, .table td {
    vertical-align: middle;
}

#map {
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
}

#map {
    height: 500px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 80%;
    }
}

@media (min-width: 1200px) {
    .modal-dialog {
        max-width: 1200px;
    }
}

.custom .modal-dialog {
    max-width: 600px;
}

/* Carousel */

ngb-carousel .carousel-control-prev-icon,
ngb-carousel .carousel-control-next-icon {
    background-color: var(--primary);
    border-radius: 10px;
    background-size: 70% 70%;
    height: 2.5rem;
    width: 2.5rem;
}

ngb-carousel .carousel-control-prev-icon {
    background-position: 40%;
}

ngb-carousel .carousel-control-next-icon {
    background-position: 60%;
}

ngb-carousel .carousel-indicators {
    background-color: var(--primary);
    border-radius: 10px;
    opacity: .5;
    transition: opacity 0.15s ease;
}

ngb-carousel .carousel-indicators:hover {
    opacity: .9;
}

ngb-carousel .picsum-img-wrapper {
    text-align: center;
}

ngb-carousel .picsum-img-wrapper img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 600px;
}

/* Cookies Bar */

.cc-window, .cc-revoke {
    color: rgb(255, 255, 255);
    background-color: var(--tertiary) !important;
}

.cc-banner {
    display: none !important;
}
